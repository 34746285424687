import React, { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { virtualize } from 'react-swipeable-views-utils';
import { withPrefix } from 'gatsby';
import * as styles from '../styles';
import Grid from './Grid';
import Heading from './Heading';
import Reveals from './Reveals';

const VirtualizeSwipeableViews = virtualize(SwipeableViews);

const modulo = (n: number, m: number) => {
  const q = n % m;
  return q < 0 ? q + m : q;
};

const StyledVirtualizeSwipeableViews = styled(VirtualizeSwipeableViews)`
  padding: 0 10%;

  ${styles.media.lessThan('medium')`
    padding: 0 5%;
  `};
`;

const projects = [
  {
    title: 'University of South Australia',
    subtitle: 'Cancer Research Institute',
    img: withPrefix('/img/projects/project-01.jpg'),
  },
  {
    title: 'University of South Australia',
    subtitle: 'Cancer Research Institute',
    img: withPrefix('/img/projects/project-02.jpg'),
  },
  {
    title: 'University of South Australia',
    subtitle: 'Cancer Research Institute',
    img: withPrefix('/img/projects/project-03.jpg'),
  },
  {
    title: 'University of South Australia',
    subtitle: 'Cancer Research Institute',
    img: withPrefix('/img/projects/project-04.jpg'),
  },
];

const ProjectWrapper = styled.div`
  display: flex;
  flex: 1;
  padding: 0 0.5rem;

  ${styles.media.greaterThan('medium')`
    padding: 0 2rem;
  `};
`;

const ProjectContainer = styled.div`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex: 1;
  position: relative;
`;

const ProjectInner = styled.div`
  padding-bottom: ${(800 / 1400) * 100}%;
  position: relative;
  width: 100%;
`;

const ProjectDetailsWrapper = styled.div`
  display: flex;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

const ProjectDetailsContainer = styled.div`
  align-items: flex-end;
  background: ${styles.palette.black}50;
  color: ${styles.palette.white};
  display: flex;
  flex: 1;
  justify-content: flex-start;
  opacity: 0;
  padding: 2rem 0;
  transition: all 300ms ease;
  width: 100%;

  &:hover,
  &:active {
    opacity: 1;
  }

  ${styles.media.greaterThan('medium')`
    padding: 4rem 1rem;
  `};
`;

const ProjectLabel = styled.div`
  text-transform: uppercase;
`;

const DotsWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
`;

const Dot = styled.div<{ active?: boolean }>`
  height: ${rem(15)};
  width: ${rem(15)};
  margin: 0.5rem;
  border: 2px solid ${styles.palette.white};
  border-radius: 50%;
  opacity: 0.5;

  ${({ active }) =>
    active &&
    css`
      background-color: ${styles.palette.white};
    `};
`;

const Projects = () => {
  const [slideIndex, setSlideIndex] = useState(0);

  const slideRenderer = ({ key, index }: { key: number; index: number }) => {
    const { img, title, subtitle } = projects[modulo(index, projects.length)];

    return (
      <ProjectWrapper key={key}>
        <ProjectContainer style={{ backgroundImage: `url(${img})` }}>
          <ProjectInner>
            <ProjectDetailsWrapper>
              <ProjectDetailsContainer>
                <Grid.Row size="expanded">
                  <Grid.Column>
                    <ProjectLabel>Project</ProjectLabel>
                    <Heading type="h5" margin="0">
                      {title}
                    </Heading>
                    <Heading type="h6" margin="0">
                      {subtitle}
                    </Heading>
                  </Grid.Column>
                </Grid.Row>
              </ProjectDetailsContainer>
            </ProjectDetailsWrapper>
          </ProjectInner>
        </ProjectContainer>
      </ProjectWrapper>
    );
  };

  return (
    <div>
      <StyledVirtualizeSwipeableViews
        index={slideIndex}
        onChangeIndex={index => setSlideIndex(index)}
        slideStyle={{ display: 'flex' }}
        slideRenderer={slideRenderer}
        enableMouseEvents={true}
      />
      <DotsWrapper>
        {projects.map((element, index) => (
          <Dot
            key={index}
            active={index === modulo(slideIndex, projects.length)}
            onClick={() => {
              const difference = modulo(slideIndex, projects.length) - index;

              let moduloIndex = slideIndex - difference;

              if (Math.abs(difference) > projects.length / 2) {
                moduloIndex =
                  moduloIndex - projects.length * -Math.sign(difference);
              }

              setSlideIndex(moduloIndex);
            }}
          />
        ))}
      </DotsWrapper>
    </div>
  );
};

export default Projects;

import styled from 'styled-components';
import * as styles from '../styles';

const Label = styled.label<{
  touched?: boolean | string;
  validationerror?: string;
}>`
  color: ${({ validationerror, touched }) =>
    !touched
      ? styles.palette.white
      : validationerror
      ? styles.palette.error
      : styles.palette.white};
  display: block;
  text-align: left;
  transition: color 300ms ease;
`;

export default Label;

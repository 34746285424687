import React from 'react';
import styled from 'styled-components';
import { withPrefix } from 'gatsby';
import Grid from './Grid';

const Wrapper = styled.div`
  left: 0;
  padding: 4rem 2rem;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9999;
`;

const Container = styled.div``;

const Header = () => {
  return (
    <Wrapper>
      <Grid.Row size="expanded">
        <Grid.Column>
          <Container>
            <img src={withPrefix('/img/logo.svg')} alt="Air Con Serve" />
          </Container>
        </Grid.Column>
      </Grid.Row>
    </Wrapper>
  );
};

export default Header;

import styled, { css } from 'styled-components';
import { CSSProp } from 'styled-components';
import { rem } from 'polished';
import * as styles from '../styles';

const headings: {
  [key: string]: {
    sizes: { [key: string]: string };
    styles: CSSProp;
  };
} = {
  h1: {
    sizes: {
      default: rem(32),
      medium: rem(50),
      large: rem(70),
      huge: rem(80),
    },
    styles: css`
      font-weight: ${styles.fontWeights.semibold};
      letter-spacing: -0.05em;
      line-height: 1;
      margin: 0 0 2rem;
    `,
  },
  h2: {
    sizes: {
      default: rem(28),
      medium: rem(40),
      large: rem(50),
      huge: rem(50),
    },
    styles: css`
      font-weight: ${styles.fontWeights.semibold};
      letter-spacing: -0.05em;
      line-height: 1;
      margin: 0 0 2rem;
    `,
  },
  h3: {
    sizes: {
      default: rem(24),
      medium: rem(26),
      large: rem(30),
      huge: rem(30),
    },
    styles: css`
      font-weight: ${styles.fontWeights.semibold};
      margin: 0 0 2rem;
      line-height: 1.2;
      letter-spacing: -0.04em;
    `,
  },
  h4: {
    sizes: {
      default: rem(24),
      medium: rem(26),
      large: rem(30),
      huge: rem(30),
    },
    styles: css`
      font-weight: ${styles.fontWeights.light};
      letter-spacing: -0.04em;
      line-height: 1.2;
      margin: 0 0 2rem;
    `,
  },
  h5: {
    sizes: {
      default: rem(20),
      medium: rem(22),
      large: rem(24),
      huge: rem(24),
    },
    styles: css`
      font-weight: ${styles.fontWeights.semibold};
      letter-spacing: -0.04em;
      line-height: 1.6;
      margin: 0 0 1rem;
    `,
  },
  h6: {
    sizes: {
      default: rem(16),
      medium: rem(16),
      large: rem(16),
      huge: rem(16),
    },
    styles: css`
      font-weight: ${styles.fontWeights.medium};
      letter-spacing: -0.04em;
      line-height: 1.8;
      margin: 0 0 0.5rem;
    `,
  },
};

const margins: { [key: string]: string } = {
  small: '0 0 1rem',
  medium: '0 0 2rem',
  large: '0 0 4rem',
  huge: '0 0 8rem',
};

const Heading = styled.div<{
  color?: string;
  textAlign?: string;
  textTransform?: string;
  type?: string;
  fontWeight?: number | string;
  margin?: string;
}>`
  color: ${({ color }) => color || styles.palette.white};
  font-family: ${styles.headingFontStack};
  text-align: ${({ textAlign }) => textAlign || 'inherit'};

  ${({ type }) =>
    type &&
    headings[type] &&
    css`
      ${headings[type].styles};
      ${Object.keys(headings[type].sizes).map(breakpoint =>
        breakpoint === 'default'
          ? css`
              font-size: ${headings[type].sizes[breakpoint]};
            `
          : styles.media.greaterThan(breakpoint)`
          font-size: ${headings[type].sizes[breakpoint]};
        `,
      )};
    `};
  ${({ fontWeight }) =>
    fontWeight &&
    css`
      font-weight: ${styles.fontWeights[fontWeight]
        ? styles.fontWeights[fontWeight]
        : fontWeight};
    `};
  ${({ fontFamily }) =>
    fontFamily &&
    css`
      font-family: ${fontFamily};
    `};
  ${({ margin }) =>
    margin &&
    css`
      margin: ${margins[margin] ? margins[margin] : margin};
    `};
`;

export default Heading;

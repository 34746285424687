import styled from 'styled-components';
import { rem } from 'polished';
import Field from './Field';

const TextArea = styled(Field)`
  font-family: inherit;
  min-height: ${rem(180)};
  padding: 1rem 1rem;
  resize: vertical;
`;

export default TextArea;

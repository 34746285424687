import styled from 'styled-components';
import * as styles from '../styles';

export const Section = styled.div<{
  backgroundColor?: string;
  color?: string;
  margin?: string;
  padding?: string;
  textAlign?: string;
}>`
  background-color: ${({ backgroundColor }) => backgroundColor || 'none'};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: ${({ color }) => color || 'none'};
  flex: 1;
  text-align: ${({ textAlign }) => textAlign || 'inherit'};
  ${styles.paddingStyles};
  ${styles.marginStyles};
`;

export default Section;

import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { withPrefix } from 'gatsby';
import * as styles from '../styles';
import Grid from './Grid';
import Heading from './Heading';
import Paragraph from './Paragraph';
import Reveals from './Reveals';

const services = [
  {
    description:
      'Whether it’s a New Construction, a Retrofit or an Upgrade to an existing system, we can handle projects of all sizes and shapes. We’ll work with you to ensure the outcome is a system that is fit for purpose.',
    src: withPrefix('/img/icons/system-engineering.svg'),
    title: 'Building Management System Engineering',
  },
  {
    description:
      'Demand Management, Active Load Control, Metering Solutions, or perhaps a Renewable Project? We can build the tools to assist your strategic decision making.',
    src: withPrefix('/img/icons/efficiency-solutions.svg'),
    title: 'Resource Management & Efficiency Solutions',
  },
  {
    description:
      'The modern BMS is far reaching, it is the backbone of your building. We’ll tailor an integrated solution to underpin the needs of your business.',
    src: withPrefix('/img/icons/integrated-services.svg'),
    title: 'Integrated Services',
  },
  {
    description:
      'Through all the phases of a buildings existence, we’ll support you every step of the way. We’ll offer design and engineering support, we’ll install it, we’ll optimise and tune it, and most importantly we’ll maintain it. And when the time is right, we’ll provide sensible upgrade advice to future proof your system.',
    src: withPrefix('/img/icons/lifecycle-management.svg'),
    title: 'Lifecycle Management',
  },
  {
    description:
      'Todays buildings must not only be functional but they have to operate as efficiently as possible. WELL, NABERS, Green Star, LEED - there’s a number of rating systems in the marketplace and the BMS plays a pivotal role in all of them. But how do you get the most out of your building? That’s where we come in….',
    src: withPrefix('/img/icons/benchmarks.svg'),
    title: 'Building Benchmarks',
  },
  {
    description:
      'Your asset is our asset too, so our skilled team of technicians are available day and night to keep your asset working for you.',
    src: withPrefix('/img/icons/servicing-asset.svg'),
    title: 'Servicing and Maintaining your Asset ',
  },
];

const ServicesWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

const ServiceWrapper = styled.div`
  flex: 0 0 auto;
  padding: 4rem 0;
  text-align: center;
  width: 100%;

  &:nth-child(2n + 1) {
    background: ${styles.palette.darkGray};
  }

  ${styles.media.greaterThan('medium')`
    padding: 6rem 0;
    width: 50%;

    &:nth-child(2n + 1){
      background: none;
    }

    &:nth-child(4n + 1),
    &:nth-child(4n + 4) {
      background: ${styles.palette.darkGray};
    }
  `};

  ${styles.media.greaterThan('large')`
    padding: 8rem 0;
  `};
`;

const ServiceContainer = styled.div`
  margin: 0 auto;
  max-width: ${rem(500)};

  ${Heading} {
    margin: 0 auto 2rem;
    max-width: ${rem(420)};
  }
`;

const ServiceImage = styled.img`
  margin-bottom: 2rem;
  width: ${rem(50)};

  ${styles.media.greaterThan('medium')`
    width: ${rem(80)};
  `};
`;

const Services = () => (
  <ServicesWrapper>
    {services.map(({ description, src, title }, index) => (
      <ServiceWrapper key={title}>
        <Grid.Row>
          <Grid.Column>
            <Reveals.FadeBottom delay={index % 2 === 1 ? 200 : 0}>
              <ServiceContainer>
                <ServiceImage src={src} />
                <Heading type="h3">{title}</Heading>
                <Paragraph>{description}</Paragraph>
              </ServiceContainer>
            </Reveals.FadeBottom>
          </Grid.Column>
        </Grid.Row>
      </ServiceWrapper>
    ))}
  </ServicesWrapper>
);

export default Services;

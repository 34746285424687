import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { withPrefix } from 'gatsby';
import * as styles from '../styles';
import AnchorButton from './AnchorButton';
import Box from './Box';
import Grid from './Grid';
import Heading from './Heading';
import Paragraph from './Paragraph';
import Section from './Section';

const ControlsTradersLogo = styled.div`
  margin-bottom: 3rem;
  padding: 0 2rem;

  img {
    max-width: ${rem(250)};

    ${styles.media.greaterThan('large')`
      max-width: ${rem(346)};
    `};
  }
`;

const ControlsTradersHeading = styled(Heading)`
  ${styles.media.greaterThan('huge')`
    font-size: ${rem(60)}
  `}
`;

const PurchaseParts = () => (
  <Section textAlign="center">
    <Grid.Row>
      <Grid.Column>
        <ControlsTradersLogo>
          <img
            alt="Controls Traders"
            src={withPrefix('/img/client-logos/controls-traders.svg')}
          />
        </ControlsTradersLogo>
        <ControlsTradersHeading type="h2" margin="0 0 4rem">
          Looking to purchase parts?
        </ControlsTradersHeading>
        <AnchorButton
          href="https://controlstraders.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Visit Site
        </AnchorButton>
        <Section margin="medium">
          <Paragraph>
            Looking for an Air Distribution or Indoor Environment Quality
            solution?
          </Paragraph>
        </Section>
        <Box size="large">
          <Grid.Row>
            <Grid.Column medium={6}>
              <a
                href="https://barcol-air.nl/en/home"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="BARCOL-AIR"
                  src={withPrefix('/img/client-logos/barcol-air.svg')}
                  width="264"
                />
              </a>
            </Grid.Column>
            <Grid.Column medium={6}>
              <a
                href="http://aircuity.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="AIRCUITY"
                  src={withPrefix('/img/client-logos/aircuity.svg')}
                  width="276"
                />
              </a>
            </Grid.Column>
          </Grid.Row>
        </Box>
      </Grid.Column>
    </Grid.Row>
  </Section>
);

export default PurchaseParts;

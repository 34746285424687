import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { withPrefix } from 'gatsby';
import * as styles from '../styles';
import Box from './Box';
import Grid from './Grid';
import Paragraph from './Paragraph';

const Wrapper = styled.div`
  color: ${styles.palette.white};
  font-family: ${styles.headingFontStack};
  margin: 6rem 0;
  text-align: center;

  ${Paragraph} {
    font-size: ${rem(16)};
    margin-bottom: 3rem;
  }
`;

const LogoWrapper = styled.div`
  margin: 4rem 0 0;

  img {
    max-width: ${rem(272)};
    width: 100%;
  }
`;

const Footer = () => (
  <Wrapper>
    <Box size="large">
      <Grid.Row>
        <Grid.Column>
          <Paragraph>
            Air Con Serve was established in 1978 and has been exclusively
            involved in the Building Automation controls industry specialising
            in innovative energy efficiency solutions. 
          </Paragraph>
          <Paragraph>
            40 Stepney Street, Stepney, Adelaide, Australia
            <br />
            +61 8 8363 3155 | contact@airconserve.com.au
          </Paragraph>
          <LogoWrapper>
            <img src={withPrefix('/img/logo-text.svg')} />
          </LogoWrapper>
        </Grid.Column>
      </Grid.Row>
    </Box>
  </Wrapper>
);

export default Footer;

import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { withPrefix } from 'gatsby';
import Grid from './Grid';
import Heading from './Heading';
import Reveals from './Reveals';

const clients = [
  {
    alt: 'UniSA',
    src: withPrefix('/img/client-logos/uni-sa.svg'),
  },
  {
    alt: 'Flinders University',
    src: withPrefix('/img/client-logos/flinders-university.svg'),
  },
  {
    alt: 'University of Adelaide',
    src: withPrefix('/img/client-logos/university-of-adelaide.svg'),
  },
  {
    alt: 'City of Adelaide',
    src: withPrefix('/img/client-logos/city-of-adelaide.svg'),
  },
  {
    alt: 'Tafe SA',
    src: withPrefix('/img/client-logos/tafe-sa.svg'),
  },
  {
    alt: 'Spotless',
    src: withPrefix('/img/client-logos/spotless.svg'),
  },
  {
    alt: 'Knight Frank',
    src: withPrefix('/img/client-logos/knight-frank.svg'),
  },
  {
    alt: 'CBRE',
    src: withPrefix('/img/client-logos/cbre.svg'),
  },
  {
    alt: 'JLL',
    src: withPrefix('/img/client-logos/jll.svg'),
  },
  {
    alt: 'Colliers International',
    src: withPrefix('/img/client-logos/colliers-international.svg'),
  },
  {
    alt: 'Government of South Australia',
    src: withPrefix('/img/client-logos/sa-government.svg'),
  },
  {
    alt: 'Built',
    src: withPrefix('/img/client-logos/built.svg'),
  },
];

const ClientsWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

const ClientLogoWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  margin-bottom: 4rem;
  min-height: ${rem(150)};
  padding: 2rem 2rem;
  width: ${100 / 3}%;
`;

const Clients = () => (
  <Grid.Row>
    <Grid.Column>
      <Heading type="h2" textAlign="center" margin="0 0 4rem">
        We’re Proud to Partner With
      </Heading>
      <ClientsWrapper>
        {clients.map(({ src, alt }, index) => (
          <ClientLogoWrapper key={alt}>
            <Reveals.FadeBottom delay={100 * (index % 3)}>
              <img src={src} alt={alt} />
            </Reveals.FadeBottom>
          </ClientLogoWrapper>
        ))}
      </ClientsWrapper>
    </Grid.Column>
  </Grid.Row>
);

export default Clients;

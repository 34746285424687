import React, { useState } from 'react';
import styled from 'styled-components';
import { Form, Formik } from 'formik';
import { withPrefix } from 'gatsby';
import * as styles from '../styles';
import Button from './Button';
import Field from './Field';
import Grid from './Grid';
import Heading from './Heading';
import Label from './Label';
import Paragraph from './Paragraph';
import TextArea from './TextArea';

const emailValidationRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const SubmitButtonWrapper = styled.div`
  padding: 2rem 0 2rem;
  text-align: center;
`;

const ContactForm = () => {
  const [submitted, setSubmitted] = useState(false);

  const [error, setError] = useState(false);

  return (
    <Formik
      initialValues={{ name: '', email: '', position: '', message: '' }}
      validateOnBlur
      validate={values => {
        let errors: {
          email?: string;
          message?: string;
          name?: string;
          position?: string;
        } = {};

        if (!values.name) {
          errors.name = 'You must enter your name';
        }

        if (!values.position) {
          errors.position = 'You must enter your position';
        }

        if (!values.email) {
          errors.email = 'You must enter an email address';
        }

        if (!emailValidationRegex.test(values.email.toLowerCase())) {
          errors.email =
            'You have entered an invalid email address please enter a real one';
        }

        if (!values.message) {
          errors.message = 'You must enter some message';
        }

        return errors;
      }}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        setSubmitted(false);
        setError(false);

        // Try and send the email with php (if fail cancel)
        try {
          const response = await fetch(
            withPrefix('/api/contact-form-handler.php'),
            {
              method: 'POST',
              body: JSON.stringify(values),
              headers: {
                'Content-Type': 'application/json',
              },
              mode: 'cors',
              referrer: 'no-referrer',
            },
          );
        } catch (err) {
          console.log({ err });
          setError(true);
        } finally {
          setSubmitting(false);
          setSubmitted(true);
          resetForm();
        }
      }}
    >
      {({ isSubmitting, errors, touched }) => (
        <Form>
          <Grid.Row>
            <Grid.Column medium={6}>
              <Label
                validationerror={errors.name}
                touched={touched.name && 'true'}
              >
                Your name
              </Label>
              <Field
                type="text"
                name="name"
                placeholder="Name"
                required=""
                validationerror={errors.name}
                touched={touched.name && 'true'}
              />
            </Grid.Column>
            <Grid.Column medium={6}>
              <Label
                validationerror={errors.position}
                touched={touched.position && 'true'}
              >
                Position
              </Label>
              <Field
                type="text"
                name="position"
                placeholder="Position"
                validationerror={errors.position}
                touched={touched.position && 'true'}
              />
            </Grid.Column>
            <Grid.Column>
              <Label
                validationerror={errors.email}
                touched={touched.email && 'true'}
              >
                Email address
              </Label>
              <Field
                type="email"
                name="email"
                placeholder="Email Address"
                required=""
                validationerror={errors.email}
                touched={touched.email && 'true'}
              />
            </Grid.Column>
            <Grid.Column>
              <Label
                validationerror={errors.message}
                touched={touched.message && 'true'}
              >
                Please leave a message
              </Label>
              <TextArea
                component="textarea"
                name="message"
                placeholder="Message"
                required=""
                touched={touched.message && 'true'}
                type="textarea"
                validationerror={errors.message}
              />
            </Grid.Column>
            <Grid.Column>
              <SubmitButtonWrapper>
                <Button type="submit" disabled={isSubmitting}>
                  Submit
                </Button>
              </SubmitButtonWrapper>
            </Grid.Column>
            {error && (
              <Grid.Column>
                <Paragraph>
                  <Heading type="h1">:(</Heading>
                  <Heading type="h6">
                    Something went wrong, message could not be sent
                  </Heading>
                </Paragraph>
              </Grid.Column>
            )}
            {submitted && (
              <Grid.Column>
                <Paragraph>
                  <Heading type="h1">:)</Heading>
                  <Heading type="h6">Thanks</Heading>
                  <Heading type="h6">
                    Your message was sent successfully..
                  </Heading>
                </Paragraph>
              </Grid.Column>
            )}
          </Grid.Row>
        </Form>
      )}
    </Formik>
  );
};

export default ContactForm;

import React, { useEffect } from 'react';
import anime from 'animejs';
import styled from 'styled-components';
import { rem } from 'polished';
import { withPrefix } from 'gatsby';
import * as styles from '../styles';
import Heading from './Heading';

const heroSlides = [
  {
    titleLine1: 'Building Management',
    titleLine2: 'System Engineering',
    subtitle: 'We’re your experts come talk to us!',
  },
  {
    titleLine1: 'We can handle projects',
    titleLine2: 'of all shapes and sizes',
    subtitle:
      'We’ll work with you to ensure the outcome is a system that is fit for purpose.',
  },
  {
    titleLine1: 'From concept',
    titleLine2: 'to reality',
    subtitle: 'We’ll support you every step of the way.',
  },
  {
    titleLine1: 'Optimal conditions for',
    titleLine2: 'optimal performance',
    subtitle: 'It’s our job to get the most out of your building.',
  },
  {
    titleLine1: 'Big Data, Internet',
    titleLine2: 'of Things, Analytics',
    subtitle:
      'They’re all nice catchphrases but someone needs to make sense of it all!',
  },
  {
    titleLine1: 'WELL, NABERS,',
    titleLine2: 'Green Star, LEED',
    subtitle: 'We’ll help get you there…',
  },
];

const HeroWrapper = styled.div`
  background: ${styles.palette.black};
  display: flex;
  min-height: 100vh;
  position: relative;
`;

const HeroContainer = styled.div`
  background-color: ${styles.palette.black};
  background-position: center;
  background-size: cover;
  display: flex;
  flex: 1;
  min-height: ${rem(800)};
  overflow: hidden;
  position: relative;
  width: 100%;

  ${styles.media.lessThan('medium')`
    min-height: ${rem(500)};
  `}
`;

const HeroVideoWrapper = styled.div`
  bottom: 0;
  left: 0;
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;

  video {
    height: auto;
    left: 50%;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: auto;
    z-index: -1;
  }
`;

const HeroContentWrapper = styled.div`
  color: ${styles.palette.white};
  padding: 4rem;
  position: relative;
  width: 100%;
  z-index: 2;
  flex: 1;
  display: flex;
  flex-flow: column;
  justify-content: space-between;

  ${styles.media.lessThan('huge')`
    padding: 2.5rem;
  `};

  ${styles.media.lessThan('small')`
    padding: 2rem;
  `};
`;

const HeroLogoWrapper = styled.div`
  margin-bottom: 6rem;

  img {
    max-width: ${rem(220)};
    width: 100%;
  }

  ${styles.media.lessThan('medium')`

    img {
      max-width: ${rem(166)};
    }
  `};
`;

const HeroSlidesWrapper = styled.div`
  flex: 1;
  position: relative;
  width: 100%;
`;

const HeroContentSlide = styled.div`
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;

  ${Heading} {
    opacity: 0;
    transform: translateY(150%);
  }
`;

const HeroTitleWrapper = styled.div`
  overflow: hidden;
`;

const HeroSubtitleWrapper = styled.div`
  overflow: hidden;
`;

const Hero = () => {
  useEffect(() => {
    const timeline = anime.timeline({
      loop: true,
      easing: 'easeOutCirc',
    });

    heroSlides.forEach((element, index) => {
      timeline
        .add(
          {
            targets: `.hero-slide-container:nth-child(${index +
              1}) .hero-slide-title, .hero-slide-container:nth-child(${index +
              1}) .hero-slide-subtitle`,
            translateY: ['150%', '0%'],
            opacity: 1,
            duration: 3000,
          },
          index === 0 ? 0 : '-=1000',
        )
        .add({
          targets: `.hero-slide-container:nth-child(${index +
            1}) .hero-slide-title, .hero-slide-container:nth-child(${index +
            1}) .hero-slide-subtitle`,
          translateY: ['0%', '150%'],
          opacity: 0,
          duration: 2000,
          delay: 7250,
        });
    });
  }, []);

  return (
    <HeroWrapper>
      <HeroContainer>
        <HeroVideoWrapper
          dangerouslySetInnerHTML={{
            __html: `<video preload="metadata" loop="" autoplay="" muted="" playsinline="">
            <source src="${withPrefix(`/video/hero.mp4`)}" type="video/mp4">
            <source src="${withPrefix(`/video/hero.webm`)}" type="video/webm">
          </video>`,
          }}
        />
        <HeroContentWrapper>
          <HeroLogoWrapper>
            <img src={withPrefix('/img/logo-title.svg')} alt="Air Con Serve" />
          </HeroLogoWrapper>
          <HeroSlidesWrapper>
            {heroSlides.map(({ titleLine1, titleLine2, subtitle }) => (
              <HeroContentSlide
                className="hero-slide-container"
                key={`${titleLine1}${titleLine2}`}
              >
                <HeroTitleWrapper>
                  <Heading type="h1" className="hero-slide-title">
                    {titleLine1}
                    <br />
                    {titleLine2}
                  </Heading>
                </HeroTitleWrapper>
                <HeroSubtitleWrapper>
                  <Heading type="h4" className="hero-slide-subtitle">
                    {subtitle}
                  </Heading>
                </HeroSubtitleWrapper>
              </HeroContentSlide>
            ))}
          </HeroSlidesWrapper>
        </HeroContentWrapper>
      </HeroContainer>
    </HeroWrapper>
  );
};

export default Hero;

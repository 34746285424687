import * as React from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import * as styles from '../styles';

const columnCount = 12;

export const rowSizes: { [key: string]: string } = {
  xxsmall: rem(370),
  xsmall: rem(525),
  small: rem(600),
  medium: rem(850),
  default: rem(1200),
  large: rem(1100),
  xlarge: rem(1270),
  xxlarge: rem(1530),
  xxxlarge: rem(1680),
  huge: rem(1800),
  expanded: 'none !important',
};

const gutters: { [key: string]: { [key: string]: number } } = {
  default: {
    default: 24,
    large: 32,
  },
  none: {
    default: 0,
  },
};

const Gutter = styled.div<{ gutter?: string }>`
  ${({ gutter }) => {
    const size = gutter && gutters[gutter] ? gutter : 'default';

    return css`
      ${Object.keys(gutters[size]).map(breakpoint =>
        breakpoint === 'default'
          ? css`
              padding: 0 ${rem(gutters[size][breakpoint] / 2)};
            `
          : styles.media.greaterThan(breakpoint)`
            padding: 0 ${rem(gutters[size][breakpoint] / 2)};
          `,
      )};
    `;
  }};
`;

interface RowWrapperProps {
  gutter?: string;
  left?: boolean;
  margin?: string;
  padding?: string;
  right?: boolean;
  size?: string;
}

const RowWrapper = styled.div<RowWrapperProps>`
  margin: 0 auto;
  max-width: ${({ size }) =>
    size && rowSizes[size] ? rowSizes[size] : rowSizes.default};
  width: 100%;

  ${({ gutter }: { gutter?: string }) => {
    const size = gutter && gutters[gutter] ? gutter : 'default';

    return css`
      ${Object.keys(gutters[size]).map(breakpoint =>
        breakpoint === 'default'
          ? css`
              padding-right: ${rem(gutters[size][breakpoint])};
              padding-left: ${rem(gutters[size][breakpoint])};
            `
          : styles.media.greaterThan(breakpoint)`
            padding-right: ${rem(gutters[size][breakpoint])};
            padding-left: ${rem(gutters[size][breakpoint])};
          `,
      )};
    `;
  }};

  ${styles.paddingStyles};
  ${styles.marginStyles};
`;

const RowContainer = styled.div<{ gutter?: string }>`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  ${({ gutter }) => {
    const size = gutter && gutters[gutter] ? gutter : 'default';

    return css`
      ${Object.keys(gutters[size]).map(breakpoint =>
        breakpoint === 'default'
          ? css`
              margin: 0 -${rem(gutters[size][breakpoint])};
            `
          : styles.media.greaterThan(breakpoint)`
          margin: 0 -${rem(gutters[size][breakpoint])};
        `,
      )};
    `;
  }};

  ${RowWrapper} {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
`;

const Row: React.SFC<
  RowWrapperProps & {
    containerProps?: object;
    gutter?: string;
    gutterProps?: object;
    size?: string;
  }
> = props => {
  const {
    children,
    gutterProps,
    containerProps,
    margin,
    padding,
    size,
    gutter,
    ...rest
  } = props;

  return (
    <RowWrapper
      size={size}
      margin={margin}
      padding={padding}
      gutter={gutter}
      {...rest}
    >
      <Gutter gutter={gutter} {...gutterProps}>
        <RowContainer gutter={gutter} {...containerProps}>
          {children}
        </RowContainer>
      </Gutter>
    </RowWrapper>
  );
};

const Column = styled.div<{
  gutter?: string;
  huge?: number;
  large?: number;
  medium?: number;
  small?: number;
}>`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex: 0 0 auto;
  max-width: 100%;
  width: 100%;

  ${({ gutter }) => {
    const size = gutter && gutters[gutter] ? gutter : 'default';

    return css`
      ${Object.keys(gutters[size]).map(breakpoint =>
        breakpoint === 'default'
          ? css`
              padding: 0 ${rem(gutters[size][breakpoint] / 2)};
            `
          : styles.media.greaterThan(breakpoint)`
            padding: 0 ${rem(gutters[size][breakpoint] / 2)};
          `,
      )};
    `;
  }};

  ${({ small }) =>
    small &&
    css`
      width: ${(small / columnCount) * 100}%;
    `};
  ${({ medium }) =>
    medium &&
    styles.media.greaterThan('medium')`
        width: ${(medium / columnCount) * 100}%;
      `};
  ${({ large }) =>
    large &&
    styles.media.greaterThan('large')`
        width: ${(large / columnCount) * 100}%;
      `};
  ${({ huge }) =>
    huge &&
    styles.media.greaterThan('huge')`
        width: ${(huge / columnCount) * 100}%;
      `};
`;

const FlexColumn = styled(Column)`
  display: flex;
  flex-flow: column;
`;

export default { Column, FlexColumn, Row, RowWrapper };

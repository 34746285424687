import '../global.css';
import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { createGlobalStyle } from 'styled-components';
import * as styles from '../styles';
import Footer from './Footer';

const GlobalStyle = createGlobalStyle`
  ${styles.globalCss}
`;

const Layout = ({ children }: { children: React.ReactNode }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={() => (
      <>
        <Helmet
          title="Air Con Serve"
          meta={[
            {
              name: 'description',
              content:
                'Air Con Serve was established in 1978 and has been exclusively involved in the Building Automation controls industry specialising in innovative energy efficiency solutions.',
            },
            {
              name: 'og:title',
              content: 'Air Con Serve',
            },
            {
              name: 'og:type',
              content: 'website',
            },
            {
              name: 'og:url',
              content: 'https://airconserve.com.au',
            },
            {
              name: 'og:image',
              content: 'https://airconserve.com.au/img/og-image.png',
            },
            {
              name: 'og:description',
              content:
                'Air Con Serve was established in 1978 and has been exclusively involved in the Building Automation controls industry specialising in innovative energy efficiency solutions.',
            },
            {
              name: 'viewport',
              content: 'width=device-width, initial-scale=1, user-scalable=no',
            },
            {
              name: 'theme-color',
              content: styles.palette.primary,
            },
          ]}
          link={[
            {
              rel: 'icon',
              type: 'image/png',
              href: '/favicon.png',
            },
            {
              rel: 'canonical',
              href: 'https://airconserve.com.au',
            },
          ]}
        />
        <GlobalStyle />
        <main>{children}</main>
        <Footer />
      </>
    )}
  />
);

export default Layout;

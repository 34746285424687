import styled from 'styled-components';
import { Field } from 'formik';
import { rgba, lighten, rem } from 'polished';
import * as styles from '../styles';

const defaultFormColor = lighten(0.2, styles.palette.primary);

const StyledField = styled(Field)`
  background-color: ${rgba(styles.palette.gray, 0.05)};
  border: 1px solid;
  border-color: ${({ validationerror, touched }) =>
    !touched
      ? rgba(styles.palette.gray, 0.5)
      : validationerror
      ? styles.palette.error
      : defaultFormColor};
  border-radius: 4px;
  box-shadow: none;
  color: ${({ validationerror, touched }) =>
    !touched
      ? styles.palette.white
      : validationerror
      ? styles.palette.error
      : defaultFormColor};
  display: block;
  font-family: ${styles.fontStack};
  font-size: ${rem(18)};
  font-weight: ${styles.fontWeights.medium};
  height: ${rem(60)};
  margin: 0.5rem 0 2rem;
  outline: 0;
  padding: 0.5rem 1rem;
  transition: color 300ms ease, border-color 300ms ease;
  width: 100%;

  &:focus {
    border-color: ${defaultFormColor};
    color: ${defaultFormColor};
  }

  &:focus + label {
    color: ${defaultFormColor};
  }

  ::placeholder {
    color: ${rgba(styles.palette.gray, 0.25)};
  }
`;

export default StyledField;

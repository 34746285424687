import { css } from 'styled-components';
import { generateMedia } from 'styled-media-query';
import { lighten, transparentize } from 'polished';

export const palette = {
  black: '#000000',
  body: '#c3cad9',
  bodyBackground: '#05051E',
  darkGray: '#1e1e34',
  error: '#f85359',
  gray: '#b6c8de',
  lightGray: '#a3acbf',
  primary: '#1e00ff',
  white: '#ffffff',
};

export const media = generateMedia({
  huge: '1440px',
  large: '1170px',
  medium: '768px',
  small: '450px',
});

export const fontWeights: { [key: string]: number } = {
  light: 300,
  normal: 400,
  medium: 500,
  roman: 550,
  semibold: 600,
  bold: 800,
  extrabold: 900,
};

export const fontStack = `'Roboto', 'Helvetica Neue Light',
  'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'`;

export const headingFontStack = `'Montserrat', ${fontStack}`;

export const globalCss = css`
  body,
  html {
    margin: 0;
    padding: 0;
  }
  body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    background-color: ${palette.bodyBackground};
    color: ${palette.body};
    cursor: move, auto;
    font-family: ${fontStack};
    font-weight: ${fontWeights.normal};
    line-height: 1.6;
    overflow-x: hidden;
    overflow-y: auto;
  }
  body * {
    box-sizing: border-box;
  }
  img {
    max-width: 100%;
  }
  ::selection {
    background: ${transparentize(0.8, lighten(0.15, palette.primary))};
    color: ${lighten(0.15, palette.primary)};
  }
  .text-center {
    text-align: center;
  }
  ${Object.keys(fontWeights).map(
    weight => css`
      .text-${weight} {
        font-weight: ${fontWeights[weight]};
      }
    `,
  )}
`;

// paddingType: { ...breakpoints }
export const paddingSizes: { [key: string]: { [key: string]: string } } = {
  xsmall: {
    default: '1rem',
    medium: '1rem',
    large: '1rem',
    huge: '1rem',
  },
  small: {
    default: '2rem',
    medium: '2rem',
    large: '2rem',
    huge: '2rem',
  },
  medium: {
    default: '3rem',
    medium: '3rem',
    large: '4rem',
    huge: '6rem',
  },
  large: {
    default: '4rem',
    medium: '4rem',
    large: '6rem',
    huge: '8rem',
  },
  xlarge: {
    default: '6rem',
    medium: '6rem',
    large: '8rem',
    huge: '10rem',
  },
  huge: {
    default: '8rem',
    medium: '10rem',
    large: '12rem',
    huge: '16rem',
  },
};

export const paddingStyles = css<{ padding?: string }>`
  ${({ padding }) =>
    padding &&
    paddingSizes[padding] &&
    css`
      ${Object.keys(paddingSizes[padding]).map(breakpoint =>
        breakpoint === 'default'
          ? css`
              padding-bottom: ${paddingSizes[padding][breakpoint]};
              padding-top: ${paddingSizes[padding][breakpoint]};
            `
          : media.greaterThan(breakpoint)`
              padding-bottom: ${paddingSizes[padding][breakpoint]};
              padding-top: ${paddingSizes[padding][breakpoint]};
            `,
      )};
    `};
`;
export const marginStyles = css<{ margin?: string }>`
  ${({ margin }) =>
    margin &&
    paddingSizes[margin] &&
    css`
      ${Object.keys(paddingSizes[margin]).map(breakpoint =>
        breakpoint === 'default'
          ? css`
              margin-bottom: ${paddingSizes[margin][breakpoint]};
              margin-top: ${paddingSizes[margin][breakpoint]};
            `
          : media.greaterThan(breakpoint)`
              margin-bottom: ${paddingSizes[margin][breakpoint]};
              margin-top: ${paddingSizes[margin][breakpoint]};
            `,
      )};
    `};
`;

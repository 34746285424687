import styled from 'styled-components';
import { rem } from 'polished';
import * as styles from '../styles';

const Button = styled.button`
  background-color: ${styles.palette.primary};
  border: 0;
  border-color: ${styles.palette.primary};
  border-radius: 500px;
  color: ${styles.palette.white};
  cursor: pointer;
  display: inline-block;
  font-size: ${rem(18)};
  font-weight: ${styles.fontWeights.medium};
  margin-bottom: 1rem;
  padding: 1rem 4rem;
  text-align: center;
  text-decoration: none;
  width: auto;
`;

export default Button;

import React from 'react';
import { Fade } from 'react-reveal';

interface RevealProps {
  children: React.ReactNode;
  delay?: number;
  left?: boolean;
  right?: boolean;
}

const FadeBottom = (props: RevealProps) => (
  <Fade bottom={true} duration={1000} distance="150px" {...props} />
);

export default {
  FadeBottom,
};

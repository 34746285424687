import styled from 'styled-components';
import { rem } from 'polished';

const sizes: { [key: string]: string } = {
  default: rem(800),
  xsmall: rem(500),
  small: rem(650),
  medium: rem(753),
  large: rem(900),
};

const Box = styled.div<{ size?: string }>`
  margin: 0 auto;
  max-width: ${({ size }) =>
    (size && sizes[size] && sizes[size]) || size || sizes.default};
`;

export default Box;
